import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import DomPurify from '@core/helpers/dom-purify';
import ImageProvider from '@core/providers/image/image-provider';

import { Graphql } from '@models/graphql';

import './card.scss';

type CardProps = {
  node: Graphql;
};

export default function Card({ node }: CardProps): React.ReactElement {
  const { t } = useTranslation();
  const { image } = node.relationships || {};

  return (
    <div className="component card card-vertical h-100">
      { node?.relationships?.image?.name && (
        <GatsbyLink to={node?.link?.uri || ''}>
          <ImageProvider
            className="card-image"
            fileName={image?.name}
            alt={image?.attributes?.alt || node?.image?.alt}
            title={image?.attributes?.title || node?.image?.title}
          />
        </GatsbyLink>
      )}
      <div className="description-container">
        <GatsbyLink to={node?.link?.uri || ''} className="card-link">
          <h5 className="card-title text-dark-grey-blue text-bold mb-3">{node.title}</h5>
        </GatsbyLink>
        <div className="card-content mt-auto h-100">
          {
            node?.type && (
              <span className="text-tangerine mt-auto text-bold text-uppercase mb-2">{t(node.type)}</span>
            )
          }
          {node?.wysiwyg?.summary && (
            <div className="card-description text-black wysiwyg">
              <DomPurify text={node.wysiwyg.summary} />
            </div>
          )}
          {node?.link?.uri && (
            <GatsbyLink to={node.link.uri} className="card-link text-nice-blue text-bold text-right mt-auto" title={node.link.title}>
              {t('See more')}
              <FontAwesomeIcon className="ml-2" icon={faAngleDoubleRight as IconProp} />
            </GatsbyLink>
          )}
        </div>
      </div>
    </div>
  );
}
